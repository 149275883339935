import React, { useState } from 'react';
import layoutData from "assets/layout_data.json";

const Footer = ({ }) => {
  const footer=layoutData.footer
  return(
  <footer
    style={{
      padding: '40px 20px',
      backgroundColor: '#1A1A2E',
      color: '#f0f0f0',
      textAlign: 'center',
      borderTop: '1px solid #333',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: '0 auto',
        paddingBottom: '20px',
        borderBottom: '1px solid #666',
      }}
    >
      {footer.company_links.map((link, index) => (
        <a
          key={index}
          href={link.url}
          style={{
            color: '#fff',
            textDecoration: 'none',
            margin: '10px 15px',
            fontSize: '14px',
            transition: 'color 0.3s ease',
          }}
          onMouseEnter={(e) => (e.target.style.color = '#aaa')}
          onMouseLeave={(e) => (e.target.style.color = '#fff')}
        >
          {link.text}
        </a>
      ))}
    </div>
    <div
      style={{
        marginTop: '20px',
        fontSize: '12px',
        color: '#a0a0a0',
      }}
    >
      &copy; {new Date().getFullYear()} {footer.company_name}. All Rights Reserved.
    </div>
  </footer>
)};

export default Footer
