// Import React and optional PropTypes
import React from 'react';
import PropTypes from 'prop-types';

// Loader Component
const Loader = ({ size, theme }) => {
  // Dynamic styles based on props
  const loaderStyles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: theme === 'dark' ? '#121212' : '#ffffff',
    },
    spinner: {
      width: size,
      height: size,
      border: `${size / 10}px solid ${theme === 'dark' ? '#444' : '#eee'}`,
      borderTop: `${size / 10}px solid ${theme === 'dark' ? '#61dafb' : '#007acc'}`,
      borderRadius: '50%',
      animation: 'spin 1s linear infinite',
    },
  };

  return (
    <div style={loaderStyles.container}>
      <div style={loaderStyles.spinner}></div>
      {/* Inline CSS keyframes */}
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

// Default props
Loader.defaultProps = {
  size: 50, // Default size in pixels
  theme: 'light',
};

// PropTypes validation
Loader.propTypes = {
  size: PropTypes.number,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default Loader;
