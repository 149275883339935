import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import layoutData from "assets/layout_data.json";

const Header = ({ }) => {
  const header = layoutData.header
  const [searchString, setSearchString] = useState("");
  const navigate = useNavigate();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      window.location.href =`/search?q=${encodeURIComponent(searchString)}`;
    }
  };

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center', // This ensures all content is vertically centered
        padding: '20px',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
      }}
    >
      <a
        href={header.logo.url}
        style={{ textDecoration: 'none'}}
      >
        <img
          src={header.logo.src}
          alt={header.logo.alt}
          style={{ cursor: 'pointer', height: 50 }}
        />
      </a>

      <nav
        style={{
          display: 'flex',
          alignItems: 'center', // Ensure the content within nav is vertically centered
          gap: '20px',
          fontSize: '16px',
        }}
      >
        <input
          type="text"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Search..."
          style={{
            padding: '8px 15px',
            marginTop: '8px',
            border: '1px solid #1a1a2e',
            borderRadius: '20px',
            fontSize: '14px',
            width: '200px',
            transition: 'border-color 0.3s ease',
          }}
        />
        {header.nav_links.map((link, index) => (
          <a
            key={index}
            href={link.url}
            style={{
              textDecoration: 'none',
              color: '#1a1a2e',
              fontWeight: 500,
              transition: 'color 0.3s ease',
            }}
            onMouseOver={(e) => (e.target.style.color = '#1A1A2E')}
            onMouseOut={(e) => (e.target.style.color = '#1a1a2e')}
          >
            {link.text}
          </a>
        ))}
      </nav>

      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Vertically center the auth buttons
          gap: '15px',
        }}
      >
        {header.auth_buttons.map((button, index) => (
          <a
            key={index}
            href={button.url}
            style={{
              padding: '10px 15px',
              border: '1px solid #1a1a2e',
              borderRadius: '8px',
              color: '#ffffff',
              backgroundColor: '#1a1a2e',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              transition: 'all 0.3s ease',
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = '#1A1A2E';
              e.target.style.borderColor = '#1A1A2E';
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = '#1a1a2e';
              e.target.style.borderColor = '#1a1a2e';
            }}
          >
            {button.text}
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header
