import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "components/Header";
import Footer from "components/Footer";
import Loader from "components/Loader";
import layoutData from "assets/layout_data.json";

const HomePage = lazy(() => import('components/HomePage'))
const ExplorePage = lazy(() => import('components/ExplorePage'))
const TrendingSkills = lazy(() => import('components/TrendingSkills'))
const CourseDetailPage = lazy(() => import('components/CourseDetailPage'))
const SearchResultPage = lazy(() => import('components/SearchResultPage'))
const CareerPage = lazy(() => import('components/CareerPage'))
const GetEnrolledPage = lazy(() => import('components/GetEnrolledPage'))
const AboutPage = lazy(() => import('components/AboutPage'))
const CourseEnrollment = lazy(() => import('components/CourseEnrollment'))


const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

// 404 Page Component
const NotFoundPage = () => (
  <>
    <Header header={layoutData.header} />
    <div style={{ textAlign: "center", padding: "2rem", minHeight: '70vh' }}>
      <h1 style={{ fontSize: "3rem", color: "#333" }}>404</h1>
      <p style={{ fontSize: "1.5rem", color: "#666" }}>
        Oops! The page you're looking for doesn't exist.
      </p>
      <a href="/" style={{ fontSize: "1rem", color: "#007bff", textDecoration: "none" }}>
        Go Back to Home
      </a>
    </div>
    <Footer footer={layoutData.footer} />
  </>
);

const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loader style={{margin: 10}} size={50} theme="light"/>}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/explore-courses" element={<ExplorePage />} />
          <Route path="/trending-skills" element={<TrendingSkills />} />
          <Route path="/course/:id" element={<CourseDetailPage />} />
          <Route path="/search" element={<SearchResultPage />} />
          <Route path="/careers" element={<CareerPage />} />
          <Route path="/get-enrolled" element={<GetEnrolledPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/enroll/:id" element={<CourseEnrollment />} />
          {/* Wildcard Route for 404 */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
